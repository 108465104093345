import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '@iso/components/utility/loader';
import NotFoundPage from '@iso/containers/Pages/404/404';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('./containers/Pages/Login'))
    },
    {
        path: PUBLIC_ROUTE.PAGE_404,
        component: lazy(() => import('@iso/containers/Pages/404/404'))
    },
    {
        path: PUBLIC_ROUTE.PAGE_500,
        component: lazy(() => import('@iso/containers/Pages/500/500'))
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import('./containers/Pages/Login'))
    },
    {
        path: PUBLIC_ROUTE.AUTH0_CALLBACK,
        component: lazy(() => import('@iso/containers/Authentication/Auth0/Auth0Callback'))
    }
];

function PrivateRoute({ children, ...rest }) {
    const isLoggedIn = useSelector(state => state.Auth.idToken);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </Route>
                        ))}
                        <PrivateRoute path="/dashboard">
                            <Dashboard />
                        </PrivateRoute>
                        <Route path="/404" component={NotFoundPage} />
                        <Redirect to="/404" />
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
